'use strict';

module.exports = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    CAPSLOCK: 20,
    ESC: 27,
    
    SPACE: 32,
    PAGE_UP: 33,
    PAGE_DOWN: 34,
    END: 35,
    HOME: 36,
    
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
    
    INSERT: 45,
    DELETE: 46,
    
    ZERO: 48,
    
    SEMICOLON: 52,
    
    A: 65,
    
    C: 67,
    D: 68,
    
    G: 71,
    
    J: 74,
    K: 75,
    
    M: 77,
    
    O: 79,
    P: 80,
    Q: 81,
    R: 82,
    S: 83,
    T: 84,
    U: 85,
    
    V: 86,
    
    X: 88,
    
    Z: 90,
    
    INSERT_MAC: 96,
    
    ASTERISK: 106,
    PLUS: 107,
    MINUS: 109,
    
    F1: 112,
    F2: 113,
    F3: 114,
    F4: 115,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121,
    
    COLON: 186,
    EQUAL: 187,
    HYPHEN: 189,
    DOT: 190,
    SLASH: 191,
    /* Typewritten Reverse Apostrophe (`) */
    TRA: 192,
    BACKSLASH: 220,
    
    BRACKET_CLOSE: 221,
};
